import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ReCAPTCHA from "react-google-recaptcha";
import {Container,Row,Col,Form,Button} from 'react-bootstrap'
import SEO from "../components/seo"

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {  fullname: '',
                        name: 'evaluation', 
                        phone :'',
                        company:'',
                        errorEmail: '',
                        errorPhone: '',
                        errorCaptcha: '',
                        errorField: '',
                        dataValue: false,
                        verified: false,
                        jobtitle:'',
                        budget:'',
                        captcha : `${process.env.CAPTCHA_SITE_KEY}`
                        
                    };
                    

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this) 
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value });
        if(event.target.name === 'email'){
            const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!pattern.test(event.target.value)) {
                this.setState({ errorEmail: 'Please enter valid email address', dataValue: false });
            }else if(pattern){
                this.setState({ errorEmail: '', dataValue: false });
            }
        }
        if(event.target.name === 'phone'){
            const pattern = /^[0-9-+#*]*$/;
            if (!pattern.test(event.target.value)) {
                this.setState({ errorPhone: 'Only numbers and Phone Character (#, -, *) allowed', dataValue: false });
            }else if(pattern){
                this.setState({ errorPhone: '', dataValue: false });
            }
        }
    }

    onChange = (value) => {
        this.setState({
            verified: true
        })
    };

    handleSubmit = (event) => {
        const {jobtitle} = this.state;
        if(jobtitle === '' || jobtitle=== 'Business Name'){
            this.setState({ errorField: 'Plese Select atleast one', dataValue: false });
        }else if (!this.state.verified) {
            this.setState({ errorCaptcha: 'Please verify Captcha', dataValue: false });
        }else if (this.state.errorEmail!=='') {
            this.setState({ errorEmail: 'Please enter valid email address', dataValue: false });
        }else if (this.state.errorPhone!=='') {
            this.setState({ errorPhone: 'Only numbers and Phone Character (#, -, *) allowed', dataValue: false });
        }else{
            fetch(`${process.env.GATSBY_API_URL}/contact`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.state)
            }).then(function (response) {
                if(response.status === 200){
                    alert("Email Sent Successfully")
                    window.location.reload();
                }else{
                    alert("Couldn't Send email");
                    window.location.reload();
                }
                
            });
        }        
        event.preventDefault();
    }
    render() {

    return(
        <Layout>
            <SEO title="Free Project Evaluation" />
            <div className="homeAuto">
                <Image alt="home automation" filename="free-evaluation.png"  />
                    <div className="freeEvalImage">
                        <h1 style={{fontWeight:"bold",color:"white",fontSize:"44px"}} className="freehead">FREE PROJECT EVALUATION</h1>
                        <p className="freeheadpara">Our experts are helping companies of all sizes<br/>
                            turn their project into profits.</p>
                    </div>
            </div>
            <Container className="contactForm">
            <br/>
            <div className="contactRow">Fill out the form below and let us know how we can help you launch your next big project!</div>
            <form method="post" onSubmit={this.handleSubmit}>
               
                    <Form.Group>
                        <Row className="mt-2">
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm" placeholder="Project Title" 
                                value={this.state.title} 
                                name="title" 
                                required="required"
                                onChange={this.handleChange} />
                            </Col>

                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control as="select" defaultValue="Project Budget" className="inputForm"
                                 value={this.state.budget} 
                                name="budget" 
                                onChange={this.handleChange} >
                                    <option>Project Budget</option>
                                    <option>Less then {'<'}$5,000 USD</option>
                                    <option>$5,000 to $20,000 USD</option>
                                    <option>$20,000 to $100,000 USD</option>
                                    <option>{'>'}$100,000 USD</option>
                                   
                                </Form.Control>
                            </Col>
                           
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm"   placeholder="Project Deadline"
                                value={this.state.deadline} 
                                name="deadline" 
                                required="required"
                                onChange={this.handleChange} />
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm" placeholder="Full Name"
                                value={this.state.fullname} 
                                name="fullname" 
                                required="required"
                                onChange={this.handleChange} />
                            </Col>
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm"   placeholder="Phone" 
                                value={this.state.phone} 
                                name="phone" 
                                required="required"
                                onChange={this.handleChange}  />
                                <span style={{ color: "red", fontSize: "12px" }} id="emailspan">{this.state.errorPhone} </span>
                            </Col>
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm" placeholder="Email" 
                                value={this.state.email} 
                                name="email" 
                                required="required"
                                onChange={this.handleChange} />
                                <span style={{ color: "red", fontSize: "12px" }}>{this.state.errorEmail}</span>
                            </Col>
                            
                           
                        </Row>
                        <Row >
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm" placeholder="Company Name"
                                value={this.state.company} 
                                name="company" 
                                required="required"
                                onChange={this.handleChange}  />
                            </Col>
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" placeholder="Industry" className="inputForm"
                                value={this.state.industry} 
                                name="industry" 
                                required="required"
                                onChange={this.handleChange}  />
                            </Col>
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control as="select" defaultValue="Job Title" className="inputForm"
                                value={this.state.jobtitle} 
                                name="jobtitle" 
                                onChange={this.handleChange} >
                                    <option>Job Title</option>
                                    <option>Owner</option>
                                    <option>Project Manager</option>
                                    <option>Product Marketeer</option>
                                    <option>Engineer</option>
                                    <option>Designer</option>
                                    <option>Other</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm" placeholder="Zip Code"
                                value={this.state.pincode} 
                                name="pincode" 
                                required="required"
                                onChange={this.handleChange}  />
                            </Col>
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm"   placeholder="City" 
                                value={this.state.city} 
                                name="city" 
                                required="required"
                                onChange={this.handleChange} />
                            </Col>
                            <Col sm={12} lg={4} className="mt-2">
                                <Form.Control type="text" className="inputForm" placeholder="State"
                                value={this.state.state} 
                                name="state" 
                                required="required"
                                onChange={this.handleChange}  />
                            </Col>
                           
                        </Row>
                        <Row  className=" headerformRow1 mt-3 ml-0">
                        <Form.Control as="textarea" rows={3} placeholder="Please let us know your project needs" className="inputForm"
                        value={this.state.message} 
                        name="message" 
                        onChange={this.handleChange} />
                        </Row>
                    </Form.Group>
               
                <ReCAPTCHA className="mt-3 ml-0"
                    sitekey={this.state.captcha}
                    onChange={this.onChange}>
                    <span style={{ color: "red", fontSize: "12px" }}>{this.state.verified ? '' : this.state.errorCaptcha}</span>
                    </ReCAPTCHA>
                
                <Button className="cntButton mt-3 ml-0" type="submit" value="submit">
                    SUBMIT
                </Button>
                </form>
                {/* <hr className="embhr" /> */}
            </Container>

        </Layout>
    );
    }
};

export default Contact;